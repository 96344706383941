<template>
  <environment-banner />
  <nuxt-loading-indicator />

  <main class="min-h-screen w-screen bg-gray-100">
    <navbar />

    <section class="mx-auto flex w-full max-w-[1400px] flex-col gap-6 p-4 py-12">
      <slot />
    </section>
  </main>
</template>
